<template>
<AppLayout>
    <template v-slot:appContent>
        <section class="users-list-wrapper">
            <div class="
            d-sm-flex
            justify-content-between
            align-items-center
            pt-2 pt-md-2 pt-xl-0
          ">
                <h2 class="">Service Agreements</h2>

            </div>
            <!-- <div class="users-list-filter px-1">
                <div class="row border rounded py-2 mb-2 align-items-center">
                    <div class="col-12 col-md-4 mb-1 mb-sm-0">
                        <label for=""></label>
                        <input v-model="appointmentConsentsParams.appointment_id" class="form-control search-product-input-element" type="text" placeholder="Search a appointment..." />
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                        <label>Status</label>
                        <VueMultiselect :allowEmpty="false" v-model="selectedStatus" class="" :options="status" :close-on-select="true" placeholder="Select status" label="name" track-by="value" :show-labels="false" />
                    </div>
                    <div class="col-12 col-md-3 mb-1 mb-sm-0">
                        <label for=""></label>
                        <button @click="applyConsentFilter(null)" class="btn btn-primary btn-block glow users-list-clear mb-0">
                            Search
                        </button>
                    </div>
                </div>
            </div> -->
            <div class="users-list-table">
                <div class="card">
                    <div class="card-body">
                        <div class="table-responsive" ref="serviceListRef">
                            <table id="users-list-datatable" class="table">
                                <thead>
                                    <tr>
                                        <th class="position-relative" :style="{ width: '25%' }">
                                            APPOINTMENT
                                            <div class="position-absolute sorting-up-button">
                                                <i class="bx bx-chevron-up"></i>
                                            </div>
                                            <div class="position-absolute sorting-down-button">
                                                <i class="bx bx-chevron-down"></i>
                                            </div>
                                        </th>
                                        <th class="position-relative" :style="{ width: '25%' }">
                                            ISSUE SOLVED
                                            <div class="position-absolute sorting-up-button">
                                                <i class="bx bx-chevron-up"></i>
                                            </div>
                                            <div class="position-absolute sorting-down-button">
                                                <i class="bx bx-chevron-down"></i>
                                            </div>
                                        </th>
                                        <th class="position-relative" :style="{ width: '20%' }">
                                            SOLVED DATE
                                            <div class="position-absolute sorting-up-button">
                                                <i class="bx bx-chevron-up"></i>
                                            </div>
                                            <div class="position-absolute sorting-down-button">
                                                <i class="bx bx-chevron-down"></i>
                                            </div>
                                        </th>
                                        <th class="position-relative" :style="{ width: '20%' }">
                                            SIGNATURE
                                            <div class="position-absolute sorting-up-button">
                                                <i class="bx bx-chevron-up"></i>
                                            </div>
                                            <div class="position-absolute sorting-down-button">
                                                <i class="bx bx-chevron-down"></i>
                                            </div>
                                        </th>

                                        <th class="position-relative" :style="{ width: '10%' }">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(appointmentConsent, key) in appointmentConsents" :key="key">
                                        <td>
                                            {{ appointmentConsent.appointment &&  appointmentConsent.appointment.id  && appointmentConsent.appointment.reference ? appointmentConsent.appointment.reference: '' }}
                                        </td>
                                        <td>
                                            <div v-if="appointmentConsent.status">
                                                <span v-if="appointmentConsent.status == 'Solved' " class="badge badge-light-success">
                                                    {{ appointmentConsent.status }}
                                                </span>
                                                <span v-else-if="appointmentConsent.status == 'Unsolved' " class="badge badge-light-danger">
                                                    {{ appointmentConsent.status }}
                                                </span>
                                                <span v-else-if="appointmentConsent.status == 'Agreed' " class="badge badge-light-primary">
                                                    {{ appointmentConsent.status }}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            {{ appointmentConsent.created_at ? appointmentConsent.created_at : '' }}
                                        </td>
                                        <td>
                                            <img :src="appointmentConsent.signature" alt="" srcset="" class="" style="width:70px;">
                                        </td>

                                        <td>
                                            <div class="d-flex align-items-start justify-content-between">
                                                <!-- <span @click="getSingleAppointment(appointment.id)" class="cursor-pointer view-edit-delete-icon" :style="{height:'18px'}">
                                                    <i class='bx bx-show'></i>
                                                </span> -->

                                                <div class="dropdown">
                                                    <span type="button" class="px-0 py-0 view-edit-delete-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="bx bx-dots-vertical-rounded h-100"></i>
                                                    </span>
                                                    <div class="dropdown-menu">

                                                        <button class="dropdown-item" @click="openAppointmentConsentDeleteModal(appointmentConsent.id)">
                                                            Delete
                                                        </button>

                                                        <button class="dropdown-item" @click="openAppointmentCloserNoteModal(appointmentConsent.id)">
                                                            View notes
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                            <div data-toggle="modal" data-target="#AppointmentServiceAgreementNote"></div>
                            <div data-toggle="modal" data-target="#AppointmentConsentDeleteAlertModal"></div>

                            <ListPagination position="right" :pagination-data="paginateLinks" @getClickedPage="applyConsentFilter" />
                            <AppointmentServiceAgreementNote :appointmentConsent="appointmentConsent" />
                            <AppointmentConsentDeleteAlertModal :model-id="appointmentConsent.id" model-name="AppointmentConsentDeleteAlertModal" modal-name="AppointmentConsentDeleteAlertModal" @confirmModelDeletion="confirmModelDeletion" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- <DeleteAlertModal /> -->
        </section>
    </template>
</AppLayout>
</template>

<script>
import Loader from "@/components/backEnd/mixins/Loader";
import AppLayout from "@/layouts/backEnd/AppLayout.vue";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import ListPagination from "@/components/backEnd/pagination/ListPagination";
import AppointmentConsentDeleteAlertModal from '@/components/backEnd/modal/DeleteAlertModal';
// import DeleteAlertModal from '@/components/backEnd/modal/DeleteAlertModal.vue'
import {
    mapActions,
    mapGetters
} from "vuex";
import AppointmentServiceAgreementNote
  from "@/views/backEnd/appointments/serviceAgreements/includes/AppointmentServiceAgreementNote";
export default {
    name: "ServiceList",
    mixins: [ShowToastMessage, Loader],
    components: {
      AppointmentServiceAgreementNote,

        AppointmentConsentDeleteAlertModal,
        AppLayout,
        // DeleteAlertModal,
        ListPagination,
    },

    /** 0 === Appointment Closing Consent **/
    data() {

        return {
            appointmentConsentsParams: {
                appointment_id: "",
                type: 1,
                with_relation: ["appointment"],
                paginate: 1,
                page: "",
                pagination: "",
            },
            appointmentConsent: {},
            selectedStatus: {
                value: "",
                name: "Any",
            },
            getSettingsParams: {
                type: ["default"],
                key: [
                    "default_pagination",

                ],
            },

        };
    },
    computed: {
        ...mapGetters({
            appointmentConsents: "appAppointmentConsents/appointmentConsents",
            paginateLinks: "appAppointmentConsents/paginateLinks",
        }),
    },
    methods: {
        ...mapActions({
            getAppointmentConsents: "appAppointmentConsents/getAppointmentConsents",
            deleteAppointmentConsentOnList: "appAppointmentConsents/deleteAppointmentConsentOnList",

            resetAppointmentConsents: "appAppointmentConsents/resetAppointmentConsents",
            getSettings: "appSettings/getSettings",

        }),
        async getSettingList(settingParams) {
            await this.getSettings(settingParams);
        },

        findAppointmentConsent(id) {
            this.appointmentConsent = this.appointmentConsents.find((appointmentConsent) => appointmentConsent.id === id);

        },

        openAppointmentCloserNoteModal(id) {
            this.findAppointmentConsent(id);
            document.querySelector('[data-target="#AppointmentServiceAgreementNote"]').click();

        },
        openAppointmentConsentDeleteModal(id) {
            this.findAppointmentConsent(id);
            document.querySelector('[data-target="#AppointmentConsentDeleteAlertModal"]').click();

        },

        async deleteSingleAppointmentConsentOnLIst(id) {
            this.deleteAppointmentConsentOnList(id).then(async (response) => {
                if (response.status === 200 || response.status === 204) {
                    const toastObj = {
                        message: "Appointment service agreement deleted successfully",
                        type: 'success'
                    };
                    this.showToastMessage(toastObj);

                }
                this.showToastMessage(response);
            });
        },

        async confirmModelDeletion(confirmModelDeletion) {
            await this.deleteSingleAppointmentConsentOnLIst(confirmModelDeletion.modelId);
        },

        async getAppointmentConcentList(appointmentConsentsParams) {
            this.loader(true);
            await this.getAppointmentConsents(appointmentConsentsParams).then((response) => {
                if (response && response.message && response.status !== 200) {
                    this.showToastMessage(response);
                }
            });
            this.loader(false);
        },
        async applyConsentFilter(pageNumber) {

            this.appointmentConsentsParams.page = pageNumber;
            await this.getAppointmentConcentList(this.appointmentConsentsParams);
        },

    },

    async mounted() {
        await this.getSettingList(this.getSettingsParams);
        await this.getAppointmentConcentList(this.appointmentConsentsParams);
    },

    beforeMount() {
        this.loader(true);
        this.resetAppointmentConsents();
    },

    watch: {

    },

};
</script>

<style scoped>
</style>
